.top {
  color: white;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  padding: 9px 0px;
}

.flex {
  display: flex;
  column-gap: 17px;

  li {
    display: flex;
    align-items: center;
    column-gap: 6px;
    position: relative;
    padding: 0 8px;

    &:nth-last-child(1) {
      cursor: pointer;
    }
  }
}

.angle {
  font-size: 14px;
}

.options {
  position: absolute;
  background: var(--darkBlue);
  padding: 5px 0px;
  top: 145%;
  left: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: 1000;
  min-width: 100%;
  border-radius: 0 0 3px 3px;

  li {
    display: flex;
    align-items: center;
  }
}

header {
  position: sticky;
  top: 0;
  background: var(--primary);
  z-index: 999;
}

.inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 17px 50px 5px 0;
  gap: 60px;
}

.logo {
  flex: 2;
  img {
    height: auto;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.links {
  display: flex;
  align-items: center;
  column-gap: 90px;
  padding: 0 20px;
}

.link {
  color: white;
  font-size: 20px;
  border-radius: 20px;
  padding: 10px 15px;
  white-space: nowrap;

  &:hover {
    color: var(--yellow);
    background: var(--darkBlue);
  }

  span {
    color: grey;
    cursor: not-allowed;
  }
}

.btns {
  display: flex;
  align-items: flex-start;
  column-gap: 35px;
  color: white;
  font-size: 30px;

  li {
    cursor: pointer;

    &:hover {
      color: var(--yellow);
    }
  }
}

.active {
  color: var(--yellow);
}

.compare {
  position: relative;
}

.count {
  position: absolute;
  top: -6px;
  right: -12px;
  font-size: 12px;
  color: var(--primary);
  border-radius: 15px;
  padding: 3px 7px;
  background: var(--yellow);
  text-align: center;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #143e59, $alpha: 0.8);
  z-index: 9999;

  form {
    top: 130px;
    left: 50%;
    transform: translate(-12%);
    width: 80%;
    z-index: 99999;
    position: sticky;
  }
}

.menu {
  display: flex;
  color: white;
  font-weight: 600;
  font-size: 18px;
  flex-direction: column;
  row-gap: 15px;
  background: var(--darkBlue);
  position: absolute;
  top: 100%;
  right: 0;
  max-width: 400px;
  width: 100%;
  padding: 15px;
  height: fit-content;
  box-shadow: -6px 0px 46px 2px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: -6px 0px 46px 2px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -6px 0px 46px 2px rgba(0, 0, 0, 0.49);

  li {
    border-radius: 20px;
    padding: 10px 15px;

    a:hover {
      color: var(--yellow);
    }

    span {
      color: grey;
      cursor: not-allowed;
    }
  }
}

@media screen and (min-width: 930px) {
  .scrolling {
    form {
      display: none;
    }

    .inner {
      align-items: center;
      padding-top: 8px;

      .logo img {
        max-width: 50px;
      }
    }
  }
}

@media screen and (max-width: 1175px) {
  .links {
    column-gap: 30px;
  }
}

@media screen and (max-width: 990px) {
  .links {
    column-gap: 10px;
  }
}

@media screen and (max-width: 930px) {
  .inner {
    align-items: center;
    padding: 7px 0;
  }

  .overlay {
    display: block;
  }

  .logo {
    img {
      max-width: 60px;
    }
  }
}

@media screen and (max-width: 500px) {
  .logo {
    img {
      max-width: 40px;
    }
  }

  .btns {
    font-size: 24px;
  }

  .overlay {
    form {
      top: 115px;
    }
  }
}

@media screen and (max-width: 400px) {
  .flex {
    font-size: 14px;
    column-gap: 7px;

    li {
      align-items: center;
    }
  }
}
