.device {
  padding: 50px 50px 30px 115px;
  background: var(--primary);
  border-radius: 2px;
  margin-bottom: 60px;
}

.top {
  width: 100%;
  margin-bottom: 35px;
  display: flex;
  align-items: center;

  span {
    text-align: center;
    flex: 2;
    margin-left: 25px;
    font-size: 24px;
    color: var(--yellow);
  }
}

.compare {
  font-size: 25px;
  cursor: pointer;
  color: white;

  &:hover {
    color: var(--yellow);
  }
}

.selected {
  color: var(--yellow);
}

.body {
  display: flex;
  column-gap: 80px;
  margin-bottom: 45px;
}

.mainImg {
  width: 100%;
  max-width: 480px;
  background: white;
  padding: 50px 0;
  border-radius: 2px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 420px;
    height: auto;
  }
}

.otherImages {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.otherImg {
  width: 110px;
  background: white;
  border-radius: 2px;
  padding: 15px 0;
  cursor: pointer;

  img {
    width: 100%;
    max-height: 75px;
  }
}

.info {
  width: 100%;
}

.mainInfo {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

.title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 700;
}

.codeId {
  margin-bottom: 30px;
  color: var(--lightGrey);
}

.price {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

.table {
  border-collapse: collapse;
  margin-bottom: 25px;
  td {
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
  }
}

.thead {
  td {
    padding: 5px 30px;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.tbody {
  font-weight: 300;
  color: var(--lightGrey);
  td {
    padding: 0 30px;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.line {
  width: 70%;
  height: 1px;
  background: var(--lightGrey);

  &:nth-last-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    display: none;
  }
}

.prices {
  color: var(--lightGrey);
  margin-bottom: 15px;
  width: fit-content;

  div {
    padding-bottom: 15px;
  }

  b {
    color: white;
    font-size: 18px;
    margin-right: 15px;
  }

  span {
    margin-left: auto;
  }
}

.cash,
.credit {
  display: flex;
  align-items: center;
}

.characteristics {
  color: white;
  margin-bottom: 15px;
  tr {
    td {
      padding-right: 40px;
      padding-bottom: 15px;

      &:first-child {
        color: var(--lightGrey);
      }
    }
  }
}

.quantity {
  margin-bottom: 35px;
  div {
    color: var(--lightGrey);
    margin-bottom: 15px;
  }
}

.counter {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: white;
}

.inc,
.dec {
  border: 1px solid white;
  border-radius: 50%;
  padding: 3px 4px;
  font-size: 12px;
  cursor: pointer;
  color: white;
  background: none;

  &:hover {
    color: var(--yellow);
    border-color: var(--yellow);
  }

  &:disabled {
    border-color: var(--grey);
    color: var(--grey);
    background: inherit;
  }
}

.btns {
  display: flex;
  column-gap: 15px;

  button {
    border-radius: 5px;
    border: 1px solid var(--yellow);

    &:hover {
      filter: brightness(0.8);
    }

    &:nth-child(1) {
      color: var(--primary);
      background: var(--yellow);
      padding: 10px 35px;
    }

    &:nth-child(2) {
      color: var(--yellow);
      background: transparent;
      padding: 10px;
    }
  }
}

.related {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.out {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;

  span {
    font-size: 20px;
    color: white;
  }

  button {
    padding: 10px;
    border: 1px solid var(--yellow);
    color: var(--yellow);
    background: transparent;
  }
}

@media screen and (max-width: 1130px) {
  .device {
    padding: 32px 17px 32px 17px;
  }
}

@media screen and (max-width: 990px) {
  .mainImg {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 410px;
    img {
      width: 400px;
      height: auto;
    }
  }

  .otherImg {
    width: 98px;

    img {
      max-height: 65px;
    }
  }
}

@media screen and (max-width: 950px) {
  .body {
    column-gap: 45px;
  }
}

@media screen and (max-width: 910px) {
  .mainImg {
    max-width: 340px;

    img {
      width: auto;
      height: 200px;
    }
  }

  .otherImg {
    width: 80px;

    img {
      max-height: 55px;
    }
  }

  .thead,
  .tbody {
    tr {
      td {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .body {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    row-gap: 20px;
  }

  .mainInfo {
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .images {
    display: flex;
    column-gap: 10px;
  }

  .mainImg {
    order: 2;
    max-width: 100%;

    img {
      height: 260px;
    }
  }

  .otherImages {
    flex-direction: column;
    order: 1;
    row-gap: 5px;
    width: 90px;
  }

  .otherImg {
    width: 100%;

    img {
      height: auto;
    }
  }

  .table {
    .tbody,
    .thead {
      tr {
        td:nth-child(1) {
          padding-left: 0;
        }
      }
    }
  }

  .line {
    width: 100%;

    &:nth-last-child(3),
    &:nth-child(2) {
      display: block;
      margin-bottom: 20px;
    }
  }

  .prices {
    margin-bottom: 5px;

    b {
      font-size: 16px;
    }
  }

  .characteristics {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 670px) {
  .images {
    column-gap: 5px;
  }
  .mainImg {
    img {
      height: 245px;
    }
  }

  .otherImages {
    width: 84px;
  }
}

@media screen and (max-width: 560px) {
  .body {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .device {
    padding: 20px 14px;
  }
  .top {
    margin-bottom: 15px;
    span {
      font-size: 18px;
    }
  }

  .compare {
    font-size: 18px;
  }

  .mainImg {
    img {
      height: 190px;
    }
  }

  .otherImages {
    overflow-y: scroll;
    max-height: 288px;
  }

  .otherImg {
    width: 100%;
    img {
      max-height: 53px;
    }
  }

  .title {
    font-size: 18px;
  }

  .codeId {
    font-size: 12px;
  }

  .price {
    font-size: 18px;
  }

  .table {
    font-size: 14px;
  }

  .characteristics {
    font-size: 14px;
    tr {
      td {
        padding-right: 22px;
        &:first-child {
          font-size: 14px;
        }
      }
    }
  }

  .quantity {
    font-size: 15px;
  }

  .inc,
  .dec {
    font-size: 8px;
  }
}

@media screen and (max-width: 395px) {
  .mainImg {
    img {
      max-height: 140px;
    }
  }

  .otherImages {
    max-height: 245px;
  }

  .otherImg {
    img {
      max-height: 43px;
    }
  }
}

@media screen and (max-width: 335px) {
  .mainImg {
    img {
      max-height: 120px;
    }
  }
  .otherImages {
    max-height: 220px;
  }

  .otherImg {
    img {
      max-height: 38px;
    }
  }
}
