.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 10px;
  padding: 25px 0;
  z-index: 5;
  position: relative;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--yellow);

  &:last-child {
    background: var(--lightGrey);
  }
}

.breadcrumb {
  color: var(--yellow);
  text-transform: capitalize;
}

.current {
  color: var(--lightGrey);
  cursor: auto;
}

@media screen and (max-width: 600px) {
  .breadcrumb {
    font-size: 12px;
  }
}
