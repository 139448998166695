.contacts {
  padding-bottom: 70px;
}

.inner {
  padding: 40px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  background: var(--primary);
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    right: -10px;
    bottom: -10px;
    border: 2px solid var(--primary);
    border-radius: 5px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 19px 35px;
  max-width: 510px;
  margin-bottom: 25px;
}

.title {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
}

.field {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;

  &:nth-last-child(1) {
    width: 100%;
    position: relative;

    span {
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 12px;
      color: var(--lightGrey);
      font-weight: 300;
    }
  }

  label {
    color: white;
    padding-left: 5px;
    font-weight: 300;
  }

  input,
  textarea {
    border-radius: 5px;
    border: 1px solid white;
    padding: 15px 10px;
    background: transparent;
    color: white;
    width: 230px;

    &:focus {
      border-color: var(--yellow);
    }

    &.invalid {
      border-color: var(--danger);
    }
  }

  textarea {
    width: 100%;
  }

  p {
    color: var(--danger);
    font-size: 12px;
    position: absolute;
    top: calc(100% + 5px);
    left: 5px;
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

.fieldInvalid {
  input {
    border-color: var(--danger) !important;
  }
}

.terms {
  font-size: 16px;
  color: var(--lightGrey);
  margin-bottom: 10px;
  display: flex;

  input {
    &:checked ~ .checkbox {
      background: var(--yellow);
      border-color: var(--yellow);
    }
  }

  span {
    margin-right: 5px;
  }

  a {
    text-decoration: underline;
    color: white;
  }
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid white;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  padding: 14px 46px;
  border-radius: 5px;
  color: var(--primary);
  background: var(--yellow);
  margin-left: auto;

  &:disabled {
    filter: grayscale(0.5);
    cursor: not-allowed;
  }
}

.required {
  color: var(--lightGrey);
  position: absolute;
  right: 0;
  top: -35px;
  font-weight: 300;
}

.check {
  color: var(--primary);
}

@media screen and (max-width: 732px) {
  .inner {
    padding: 10px 15px;
    padding-top: 25px;
  }
}

@media screen and (max-width: 564px) {
  .inner {
    padding: 25px 40px;

    &::after {
      display: none;
    }
  }

  .phoneField {
    margin-bottom: 20px;
    p {
      top: calc(100% + 27px);
    }
  }

  .field {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 365px) {
  .inner {
    padding: 25px 10px;
  }
  .terms {
    font-size: 14px;
  }
}
