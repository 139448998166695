.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 60px;
}

.img {
  border-radius: 5px;
}

.text {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  color: var(--yellow);
  margin-bottom: 25px;
  text-transform: uppercase;
}

.subtitle {
  font-size: 20px;
  max-width: 480px;
  color: white;
  font-weight: 300;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1110px) {
  .inner {
    column-gap: 20px;
  }
  .img {
    width: 520px;
    height: auto;
  }

  .subtitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .img {
    width: 420px;
  }
}

@media screen and (max-width: 768px) {
  .inner {
    flex-direction: column-reverse;
    row-gap: 20px;
  }

  .img {
    width: 480px;
  }
}

@media screen and (max-width: 520px) {
  .inner {
    align-items: normal;
  }

  .img {
    width: auto;
  }

  .mobile {
    display: block;
  }

  .subtitle {
    line-height: 25px;
  }

  .subtitle p {
    display: none;
  }
}
