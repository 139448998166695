.pagination {
  list-style: none;
  padding-bottom: 60px;
  margin: 0 auto;
  z-index: 1;

  li {
    margin-right: 5px;
    display: inline-flex;

    a {
      width: 24px;
      height: 24px;
      border: 1px solid var(--yellow);
      color: var(--yellow);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  li.selected {
    a {
      color: var(--primary) !important;
      background: var(--yellow) !important;
    }
  }

  li.disabled {
    opacity: 0.5;
    a {
      cursor: auto;
    }
  }
}

@media screen and (max-width: 400px) {
  .pagination li {
    margin-right: 2px;
    a {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  }
}
