.title {
  background: var(--primary);
  color: white;
  padding: 20px 0;
  letter-spacing: 0.03cm;
  margin-bottom: 15px;
  font-size: 24px;
  z-index: 5;
  position: relative;

  h3 {
    font-size: 24px;
    text-transform: capitalize;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .title h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 685px) {
  .inner {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 25px;
  }
}
