@font-face {
  font-family: 'Roboto';
  src: url('../../public/fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../public/fonts/FiraSans-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

body {
  background: var(--darkBlue);
  font-family: 'Fira Sans';
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  border: none;
  font-family: 'Fira Sans';

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    border: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    border: inherit;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
  border: none;
}

main {
  background: var(--darkBlue);
  display: flex;
  column-gap: 30px;
  min-height: 100vh;
  padding: 30px 15px;
}

.flex {
  display: flex;
  column-gap: 20px;
}

.block-850 {
  display: none;
  align-self: center;
}

.none-850 {
  display: flex;
  min-width: 280px;
}

.sidebar {
  display: none;
  position: absolute;
  min-width: 280px;
  top: -16px;
  left: -10px;
  height: 100%;

  &__inner {
    z-index: 998;
    position: relative;
  }

  &__overlay {
    background: var(--darkBlue);
    opacity: 0.8;
    right: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
  }
}

@media screen and (max-width: 1070px) and (min-width: 990px) {
  .none-850 {
    min-width: 240px;
  }
}

@media screen and (max-width: 620px) {
  .sidebar__inner {
    max-width: 245px;
  }
}

@media screen and (max-width: 600px) {
  .sidebar__overlay {
    width: 100vw;
  }
}

@media screen and (max-width: 990px) {
  .block-850 {
    display: block;
  }

  .none-850 {
    display: none;
  }

  .sidebar {
    display: block;
  }
}

@media screen and (max-width: 685px) {
  .between {
    width: 100%;
    justify-content: space-between;
  }
}

// Transition

.cart-notification-enter {
  opacity: 0;
  transform: scale(0.9);
}
.cart-notification-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.cart-notification-exit {
  opacity: 1 !important;
}
.cart-notification-exit-active {
  opacity: 0 !important;
  transform: scale(0.9) !important;
  transition: opacity 300ms, transform 300ms;
}

.sidebar-enter {
  opacity: 1;
  transform: translateX(-300px);
}
.sidebar-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.sidebar-exit {
  opacity: 1;
}
.sidebar-exit-active {
  opacity: 1;
  transform: translateX(-300px);
  transition: opacity 300ms, transform 300ms;
}

.cart-enter {
  opacity: 1;
  transform: translateX(750px);
}
.cart-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
}
.cart-exit {
  opacity: 1;
}
.cart-exit-active {
  opacity: 1;
  transform: translateX(750px);
  transition: opacity 400ms, transform 400ms;
}

// Lib

.swiper-pagination-bullet-active {
  transform: scale(1.4) !important;
}

.swiper-pagination-bullet {
  &:hover {
    background: var(--primary);
    cursor: pointer !important;
    opacity: 1 !important;
  }
}

.phone-slider {
  .swiper-slide-next,
  .swiper-slide-prev {
    transform: scaleY(0.8) !important;
  }
}

.example-track.example-track-1 {
  height: 5px;
  top: 2px !important;
  background: var(--yellow);
}

.example-track-0,
.example-track-2 {
  height: 5px;
  top: 2px !important;
  background: var(--lightGrey);
  border-radius: 5px;
}

.horizontal-slider .example-thumb.example-thumb-0,
.example-thumb.example-thumb-1 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--yellow);
}

.PhoneInputCountry {
  left: 20px;
  top: 11px;
}

.PhoneInputCountrySelect {
  background: var(--primary);
  color: white;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--darkBlue);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--yellow);
    border-radius: 10px;
  }
}

.PhoneInputCountrySelectArrow {
  color: white;
  opacity: 1;
  height: 7px;
  width: 7px;
  margin-left: 10px;
}

.PhoneInputInput {
  position: absolute;
  top: 24px;
  left: 0;
  padding-left: 70px !important;
}

.PhoneInputCountryIcon {
  width: 24px;
  height: 24px;
}

.PhoneInputCountryIconImg {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.PhoneInputCountryIconImg {
  object-fit: cover;
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
  background: transparent;
}

.notification {
  opacity: 1;
  box-shadow: none;
  padding: 15px 15px 10px 24px;
}

.notification-error {
  background-color: var(--yellow) !important;
  h4 {
    font-weight: 500 !important;
  }

  &::before {
    display: none;
  }
}
