.orderForm {
  padding-bottom: 60px;
}

.blocks {
  display: flex;
  column-gap: 30px;
}

.block {
  width: 100%;
  background: var(--primary);
  border-radius: 2px;
  padding: 32px 38px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 40px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 19px 35px;
  max-width: 510px;
  margin-bottom: 25px;
}

.field {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;

  &:nth-last-child(2) {
    position: relative;
    margin-bottom: 20px;

    span {
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 12px;
      color: var(--lightGrey);
      font-weight: 300;
    }
  }

  label {
    color: white;
    padding-left: 5px;
    font-weight: 300;
  }

  input,
  textarea {
    border-radius: 5px;
    border: 1px solid white;
    padding: 15px 10px;
    background: transparent;
    color: white;
    width: 230px;

    &:focus {
      border-color: var(--yellow);
    }

    &.invalid {
      border-color: var(--danger);
    }
  }

  > p {
    color: var(--danger);
    font-size: 12px;
    position: absolute;
    top: calc(100% + 5px);
    left: 5px;
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

.selectedOption {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  cursor: pointer;
}

.opened {
  border-color: var(--yellow);
}

.optionsHolder {
  position: absolute;
  top: 110%;
  background: var(--darkBlue);
  z-index: 2;
  border-radius: 5px;
  padding: 5px 0;
  color: white;
  width: 100%;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.6);
}

.searchPanel {
  width: 100%;
  position: relative;
  padding-left: 5px;
  margin-bottom: 5px;

  input {
    width: 100% !important;
    padding: 10px 5px;
    font-weight: 300;
    border-color: var(--lightGrey);
    &:focus {
      border-color: var(--lightGrey);
    }
  }
}

.searchIcon {
  position: absolute;
  top: 30%;
  right: 10px;
  color: var(--lightGrey);
}

.lightGrey {
  color: var(--lightGrey);
}

.options {
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--darkBlue);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5px;
  }

  > p {
    text-align: center;
    padding-top: 20px;
    margin-left: 10px;
  }
}

.option {
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: var(--yellow);
  }
}

.loader {
  margin: auto;
  width: 48px;
  height: 48px;
  border: 5px solid var(--yellow);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.textarea {
  width: 100%;

  textarea {
    width: 100%;
  }
}

.fieldInvalid {
  input {
    border-color: var(--danger) !important;
  }
}

.terms {
  font-size: 16px;
  color: var(--lightGrey);
  margin-bottom: 10px;
  display: flex;

  input {
    &:checked ~ .checkbox {
      background: var(--yellow);
      border-color: var(--yellow);
      color: var(--primary);
    }
  }

  span {
    margin-right: 5px;
  }

  a {
    text-decoration: underline;
    color: white;
  }
}

.checkbox {
  width: 100%;
  max-width: 18px;
  height: 18px;
  border: 1px solid white;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment {
  padding-top: 20px;
  border-top: 1px solid rgba($color: white, $alpha: 0.3);
  width: 100%;
  margin-top: 40px;

  h3 {
    font-size: 22px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }

  span {
    font-weight: 300;
    color: white;
  }
}

.methods {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  row-gap: 15px;
  padding-top: 18px;

  p {
    color: white;
    width: 50px;
  }
}

.method {
  cursor: pointer;
  div {
    display: flex;
    align-items: flex-start;
    column-gap: 6px;
  }
}

.radio {
  width: 15px;
  height: 15px;
  border: 2px solid var(--lightGrey);
  border-radius: 50%;
}

.icon {
  width: 120px;
  height: 80px;
  display: flex;
  margin-bottom: 15px;
  border: 2px solid var(--lightGrey);
  border-radius: 5px;

  img {
    margin: auto;
    max-width: 90px;
  }
}

.selected {
  .radio {
    background: white;
  }
}

.btn {
  color: var(--primary);
  background: var(--yellow);
  font-weight: 700;
  border-radius: 2px;
  padding: 13px 91px;
  margin-top: 60px;

  &:disabled {
    filter: grayscale(0.5);
    cursor: not-allowed;
  }
}

.error {
  align-self: flex-start;
  color: var(--danger);
  font-weight: 300;
}

.devices {
  border-top: 1px solid var(--darkBlue);
  width: 100%;
  max-height: 500px;
  margin-bottom: 20px;
  overflow-y: auto;
  display: inline-block;
  color: white;

  tbody {
    display: inline-table;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--primary);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--darkBlue);
  }

  tr {
    td {
      padding: 12px 9px;
      border-bottom: 1px solid var(--darkBlue);
    }
  }
}

.remove {
  font-size: 22px;
  color: var(--lightGrey);
  padding-right: 20px !important;
  cursor: pointer;
}

.name {
  color: var(--lightGrey);
}

.count {
  border: 1px solid var(--yellow);
  padding: 4px 14px;
  border-radius: 2px;
  min-width: 50px;
}

.total {
  color: var(--lightGrey);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back {
  display: none;
}

.deviceImg {
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  border-radius: 2px;

  img {
    max-width: 50px;
  }
}

@media screen and (max-width: 1210px) {
  .block {
    padding: 32px 15px;
  }
}

@media screen and (max-width: 1117px) {
  .field {
    input {
      width: 200px;
    }
  }

  .selectedOption {
    width: 200px;
  }

  .icon {
    width: 110px;
  }
}

@media screen and (max-width: 1120px) and (min-width: 1000px) {
  .method {
    font-size: 14px;
  }
}

@media screen and (max-width: 1070px) {
  .icon {
    width: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .field {
    width: 100%;
    input {
      width: 100%;
    }
  }

  .selectedOption {
    width: 100%;
  }

  .phoneField {
    margin-bottom: 20px;
    p {
      top: calc(100% + 27px);
    }
  }

  .methods {
    flex-wrap: wrap;
  }

  .method {
    font-size: 16px;
  }

  .icon {
    width: 120px;
  }
}

@media screen and (max-width: 850px) {
  .blocks {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .block {
    padding: 32px 40px;
    width: fit-content;

    &:nth-child(2) {
      width: 590px;
    }
  }

  .fields {
    justify-content: center;
  }

  .field {
    width: 100%;
    max-width: 400px;
    input {
      width: 100%;
    }
  }

  .terms {
    max-width: 400px;
  }
  .paymentMethods {
    padding-top: 40px;
  }
}

@media screen and (max-width: 630px) {
  .block:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 530px) {
  .block {
    padding: 20px 12px;
    width: 100%;
  }

  .fields {
    justify-content: center;
  }

  .field {
    width: 100%;
    max-width: 400px;
    input {
      width: 100%;
    }
  }

  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .paymentMethods {
    padding-top: 20px;

    li {
      max-width: 57px;
      padding: 0;
    }

    img {
      max-width: 52px;
      padding: 0 1px;
    }
  }
}

@media screen and (max-width: 450px) {
  .devicesMobile {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--darkBlue);
  }

  .deviceMobile {
    display: flex;
    position: relative;
    column-gap: 18px;
    color: white;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0;
    justify-content: space-between;
  }

  .remove {
    position: absolute;
    top: 29px;
    right: 0;
  }

  .deviceMobileImg {
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    img {
      max-width: 78px;
      max-height: 78px;
    }
  }

  .back {
    display: block;
    color: var(--yellow);
  }
}

@media screen and (max-width: 330px) {
  .btn {
    padding: 13px 85px;
  }
}
