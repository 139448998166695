.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.title {
  color: white;
  text-align: center;
  margin-bottom: 22px;
  font-size: 38px;
}

.mainTitle {
  font-size: 24px;
  text-align: left;
  color: white;
  margin-bottom: 40px;
}

.all {
  width: fit-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  font-size: 18px;
  color: var(--lightGrey);
  margin-bottom: 20px;

  &:hover {
    filter: brightness(0.7);
  }

  span {
    font-size: 16px;
  }
}

@media screen and (max-width: 940px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 610px) {
  .grid {
    gap: 10px;
  }
}

@media screen and (max-width: 520px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }
}

@media screen and (max-width: 460px) {
  .grid {
    gap: 10px;
    padding: 0;
  }
}
