.thanks {
  padding: 60px 0;
}

.block {
  background: var(--primary);
  padding: 86px 0;
  margin: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    margin-bottom: 45px;
  }

  a {
    z-index: 2;
  }

  &:after {
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    right: -10px;
    bottom: -10px;
    border: 2px solid var(--primary);
    border-radius: 5px;
  }
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: var(--yellow);
  margin-bottom: 10px;
  text-align: center;
}

.subtitle {
  font-size: 18px;
  color: white;
  margin-bottom: 30px;
  max-width: 270px;
  text-align: center;
}

.btn {
  padding: 10px 35px;
  color: var(--primary);
  background: var(--yellow);
  border-radius: 2px;
  font-weight: 500;
}

@media screen and (max-width: 700px) {
  .thanks {
    padding: 40px 0;
  }
  .block {
    padding: 30px 0 25px;
    margin: 0;
    border-radius: 5px;
    &::after {
      display: none;
    }
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 12px;
  }
}
