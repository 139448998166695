.devices {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 60px;
}

.nothingFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
  font-size: 28px;
  font-weight: 600;
  color: var(--lightGrey);
}

@media screen and (max-width: 1154px) and (min-width: 1130px) {
  .list {
    gap: 10px;
  }
}

@media screen and (max-width: 1030px) and (min-width: 1020px) {
  .list {
    gap: 10px;
  }
}

@media screen and (max-width: 990px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
  .devices {
    min-height: 700px;
  }
  .nothingFound {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 940px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .nothingFound {
    font-size: 24px;
    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media screen and (max-width: 610px) {
  .list {
    gap: 10px;
  }
}

@media screen and (max-width: 520px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }

  .nothingFound {
    font-size: 22px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}

@media screen and (max-width: 520px) and (min-width: 480px) {
  .list {
    gap: 20px;
    padding: 0 15px;
  }
}
