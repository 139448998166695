.inner {
  gap: 11px 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 60px;
}

.categorie {
  display: flex;
  align-items: center;
  row-gap: 10px;
  flex-direction: column;
  color: var(--yellow);
  background: var(--primary);
  font-size: 50px;
  padding: 10px 0;
  max-width: 215px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  border-bottom: 1px solid transparent;
  box-shadow: 3px 4px 15px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    border-color: var(--yellow);
    span {
      color: var(--yellow);
    }
  }

  img {
    height: auto;
  }

  span {
    font-size: 20px;
    color: white;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .inner {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 770px) {
  .categorie {
    img {
      width: 85px;
    }
  }
  .inner {
    column-gap: 10px;
  }
}

@media screen and (max-width: 710px) {
  .categorie {
    row-gap: 7px;
    img {
      width: 66px;
    }
    span {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 650px) {
  .inner {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
}

@media screen and (max-width: 530px) {
  .inner {
    column-gap: 10px;
  }
}

@media screen and (max-width: 495px) {
  .inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 495px) and (min-width: 380px) {
  .inner {
    column-gap: 20px;
  }

  .categorie {
    img {
      width: 75px;
    }
  }
}
