.overlay {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.cart {
  z-index: 10;
  height: 100vh;
  padding: 43px 79px 37px;
  background: var(--darkBlue);
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 600px;
}

.close {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 24px;
  cursor: pointer;
  color: var(--lightGrey);
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.deleteAll {
  color: var(--lightGrey);
  text-decoration: underline;
  cursor: pointer;
  text-align: end;
  margin-bottom: 10px;
  cursor: pointer;
}

.items {
  border-top: 1px solid var(--primary);
  max-height: 500px;
  margin-bottom: 30px;
  display: inline-block;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--darkBlue);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }

  tr {
    td {
      padding: 9px;
      border-bottom: 1px solid var(--primary);

      div {
        max-width: 185px;
      }
    }
  }
}

.remove {
  font-size: 22px;
  color: var(--lightGrey);
  padding-right: 20px !important;
  cursor: pointer;
}

.name {
  color: var(--lightGrey);
}

.counter {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.count {
  border: 1px solid var(--yellow);
  padding: 4px 14px;
  border-radius: 2px;
  min-width: 50px;
}

.inc,
.dec {
  border: 1px solid white;
  border-radius: 50%;
  padding: 3px 4px;
  font-size: 8px;
  cursor: pointer;
}

.outOfStock {
  margin-right: 5px;
}

.contactUs {
  border-radius: 2px;
  border: 1px solid var(--yellow);
  color: var(--yellow);
  background: transparent;
  padding: 5px;
  font-size: 14px;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
}

.order {
  border-top: 1px solid var(--primary);
  padding-top: 10px;
  margin-top: auto;
}

.disabled {
  cursor: default;
  border-color: var(--grey);
  color: var(--grey);
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 0 20px;

  span {
    color: var(--lightGrey);
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}

.back {
  color: var(--yellow);
  cursor: pointer;
}

.done {
  padding: 10px 55px;
  color: var(--primary);
  background: var(--yellow);

  &:disabled {
    filter: grayscale(0.5);
    cursor: not-allowed;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.emptyCart {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-top: 50px;
  text-align: center;

  img {
    margin-bottom: 40px;
  }

  span {
    color: var(--lightGrey);
    font-size: 20px;
  }

  .back {
    margin-top: auto;
  }
}

span.empty {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

@media screen and (max-width: 1000px) {
  .cart {
    padding-left: 25px;
    padding-right: 25px;
  }

  .close {
    right: 20px;
  }
}
@media screen and (max-width: 700px) {
  .card {
    background: var(--primary);
    width: 100%;
    padding: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
  }

  .device {
    display: flex;
    column-gap: 15px;
    align-items: center;
    border-bottom: 1px solid var(--darkBlue);
    padding-bottom: 15px;
    margin-bottom: 15px;

    .img {
      background: white;
      min-height: 85px;
      min-width: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .deviceInfo {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .remove {
    position: absolute;
    right: -12px;
    top: 5px;
  }

  .devicePrice {
    margin-left: auto;
    font-weight: 700;
  }
}

@media screen and (max-width: 620px) {
  .cart {
    width: 100%;
    min-width: auto;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }

  .close {
    right: 15px;
  }

  .cart {
    padding: 20px 16px;
  }

  .total {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .btns {
    flex-direction: column-reverse;
    row-gap: 20px;
  }

  .deviceInfo {
    p {
      font-size: 14px;
    }
  }

  .emptyCart {
    img {
      width: 170px;
      height: 170px;
      margin-bottom: 25px;
    }

    span {
      font-size: 18px;
    }
  }

  span.empty {
    font-size: 20px;
  }
}
