.search {
  position: relative;

  h2 {
    color: white;
    text-align: center;
    background: var(--darkBlue);
    padding: 40px 0 15px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
  }
}

.searchPanel {
  padding: 11px 70px 11px 34px;
  background: #d4d4d4;
  color: black;
  border-radius: 20px;
  width: 100%;
  z-index: 12;
  position: relative;

  &::placeholder {
    color: var(--grey);
  }
}

.searchIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: var(--yellow);
  border-radius: 20px;
  padding: 8px 20px;
  color: var(--primary);
  font-size: 20px;
  z-index: 12;
}

.devices {
  width: 100%;
  background: var(--darkBlue);
  position: absolute;
  top: 50%;
  left: 0;
  color: white;
  z-index: 11;
  border-radius: 5px;

  tbody {
    tr {
      cursor: pointer;
      &:first-child {
        td {
          padding-top: 40px;
        }
      }

      &:last-child {
        td {
          border: none;
        }
      }
      td {
        padding: 15px;
        border-bottom: 1px solid var(--primary);
        a {
          display: inline-block;
          width: 100%;
        }
        img {
          max-width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .devices {
    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 25px;
          }
        }
        td {
          padding: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .devices {
    tbody {
      font-size: 15px;
      tr {
        td {
          img {
            max-width: 85px;
          }
        }
      }
    }
  }

  .search {
    h2 {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 410px) {
  .devices {
    tbody {
      font-size: 14px;
      tr {
        td {
          img {
            max-width: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .devices {
    tbody {
      font-size: 14px;
      tr {
        td {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
