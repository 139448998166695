.compare {
  padding: 0 20px 60px;

  h3 {
    text-align: center;
    color: var(--lightGrey);
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 130px 0 70px;
  margin-bottom: 50px;

  h2 {
    color: white;
    flex: 2;
    white-space: nowrap;
    margin-right: 15px;
  }

  p {
    color: var(--lightGrey);
    text-decoration: underline;
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      color: var(--yellow);
    }
  }
}

.filters {
  display: flex;
  align-items: center;
  flex: 8;

  li {
    color: var(--yellow);
    border: 1px solid var(--yellow);
    padding: 10px 25px;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 10px;

    &:hover {
      color: var(--primary);
      background: var(--yellow);
    }
  }
}
.selected {
  color: var(--primary) !important;
  background: var(--yellow);
}

@media screen and (max-width: 1200px) {
  .filters {
    flex: 6;
  }

  .top {
    padding-right: 50px;
  }
}

@media screen and (max-width: 950px) {
  .filters {
    flex: 6;
  }

  .top {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 800px) {
  .filters {
    flex: 4;
  }
}

@media screen and (max-width: 680px) {
  .filters {
    flex: 3;
  }
}

@media screen and (max-width: 615px) {
  .top {
    white-space: nowrap;
    overflow: auto;
    column-gap: 25px;
    h2 {
      font-size: 20px;
    }
  }
  .filters {
    li {
      padding: 8px 20px;
      font-size: 14px;
    }
  }
}
