.sidebar {
  width: 100%;
  max-width: 280px;
  margin-bottom: 60px;
  font-family: 'Roboto';
}

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 5px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.item {
  color: var(--lightGrey);
  border: 1px solid var(--lightGrey);
  padding: 8px 25px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    color: var(--yellow);
    border-color: var(--yellow);
  }
}

.active {
  color: var(--primary);
  background: var(--yellow);
  border-color: var(--yellow);

  &:hover {
    color: var(--primary);
  }
}

.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.field {
  display: flex;
  align-items: center;
  column-gap: 10px;

  label {
    color: var(--lightGrey);
    font-size: 12px;
    font-weight: 300;
  }

  input {
    width: 100%;
    max-width: 70px;
    border-radius: 2px;
    border: 1px solid var(--yellow);
    color: var(--yellow);
    background: transparent;
    padding: 4px;

    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 1070px) and (min-width: 991px) {
  .sidebar {
    max-width: 240px;
  }
}

@media screen and (max-width: 990px) {
  .sidebar {
    margin: 0;
  }
  .accordion {
    gap: 0;
    margin-bottom: 0;

    max-height: 600px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: var(--primary);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--darkBlue);
      border-radius: 10px;
      border-right: 1px solid var(--primary);
      border-left: 1px solid var(--primary);
    }
  }
}

@media screen and (max-width: 620px) {
  .items {
    font-size: 14px;
  }
}
