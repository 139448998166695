footer {
  background: var(--primary);
  font-family: "Roboto";
}

.inner {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.nav {
  display: flex;
  align-items: center;
  font-size: 20px;
  column-gap: 80px;
  color: white;
  padding: 28px 0;
  margin-bottom: 30px;
  font-family: "Fira Sans";

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: translateY(40px);
    height: 1px;
    opacity: 0.5;
    left: 0;
    background: var(--darkBlue);
  }

  li:hover {
    color: var(--yellow);
  }

  span {
    color: grey;
    cursor: not-allowed;
  }
}

.logo {
  margin-bottom: 20px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 130px;
  color: var(--lightGrey);
  margin-bottom: 50px;

  li {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}

.social {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}

.policy {
  color: white;

  &:hover {
    color: var(--yellow);
    cursor: pointer;
  }
}

.socialLink {
  border: 1px solid var(--yellow);
  border-radius: 2px;
  color: var(--yellow);
  font-size: 20px;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--yellow);
    color: var(--darkBlue);
  }

  &:nth-child(1) {
    svg {
      transform: translateY(1px);
    }

    &:hover {
      svg > path {
        fill: var(--darkBlue);
      }
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-align: center;
}

.payments {
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.top {
  position: absolute;
  right: 0;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 5px;
  color: var(--yellow);
  border: 1px solid var(--yellow);
  border-radius: 2px;
  width: 54px;
  height: 54px;
  cursor: pointer;

  &:hover {
    background: var(--yellow);
    color: var(--darkBlue);
  }
}

@media screen and (max-width: 740px) {
  .nav {
    column-gap: 60px;
  }
}

@media screen and (max-width: 824px) {
  .info {
    column-gap: 50px;
  }

  .payments {
    img {
      height: auto;
      max-width: 60px;
    }
  }
}

@media screen and (max-width: 680px) {
  .info {
    column-gap: 25px;
  }
}

@media screen and (max-width: 615px) {
  .logo {
    display: none;
  }

  .nav {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    padding: 0;

    &::after {
      display: none;
    }

    li {
      padding: 17px 0;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100vw;
        height: 1px;
        top: 100%;
        opacity: 0.5;
        left: -10px;
        background: var(--darkBlue);
      }
    }
  }

  .info {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      width: 100vw;
      height: 1px;
      top: 120%;
      opacity: 0.5;
      left: -10px;
      background: var(--lightGrey);
    }
  }

  .top {
    display: none;
  }

  .bottom {
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 10px;

    p {
      order: 2;
    }
  }

  .payments {
    order: 1;
    column-gap: 10px;
  }
}

@media screen and (max-width: 380px) {
  .payments {
    img {
      max-width: 50px;
    }
  }
}
