.item {
  background: var(--primary);
  padding: 26px 0;
  color: white;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 480px;
  font-family: "Roboto";
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    img {
      max-width: 230px;
    }
    .name {
      color: var(--yellow);
    }
  }
  img {
    height: auto;
  }
}

.top {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 35px;

  span {
    text-align: center;
    flex: 2;
    margin-left: 20px;
    font-size: 16px;
  }
}

.compare {
  font-size: 20px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    color: var(--yellow);
  }
}

.name {
  text-align: center;
  font-size: 16px;
  margin-bottom: 29px;
  padding: 0 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imgHolder {
  height: 160px;
  margin-bottom: 20px;
}

.price {
  font-size: 20px;
  margin-bottom: 20px;
}

.oldPrice {
  color: var(--lightGrey);
  margin-bottom: 32px;
  text-decoration: line-through;
  font-size: 16px;
}

.btns {
  display: flex;
  column-gap: 20px;

  button {
    border-radius: 2px;
    border: 1px solid var(--yellow);

    &:hover {
      filter: brightness(0.8);
    }

    &:nth-child(1) {
      color: var(--primary);
      background: var(--yellow);
      padding: 10px 35px;
    }

    &:nth-child(2) {
      color: var(--yellow);
      background: transparent;
      padding: 10px;
    }
  }
}

.out {
  font-size: 24px;
  margin-bottom: 67px;
}

.contactUs {
  border-radius: 2px;
  border: 1px solid var(--yellow);
  color: var(--yellow);
  background: transparent;
  padding: 10px;

  &:hover {
    filter: brightness(0.8);
  }
}

.itemType {
  width: 75px;
  height: auto;
  top: -16px;
  left: -18px;
  position: absolute;
}

.newCollectionImg {
  top: -5px;
  left: 10px;
}

.saleImg {
  top: 0;
  left: -6px;
}

.selected {
  color: var(--yellow);
}

@media screen and (max-width: 1130px) {
  .imgHolder {
    img {
      max-width: 230px;
    }
  }

  .body {
    &:hover {
      img {
        max-width: 200px;
      }
    }
  }
}

@media screen and (max-width: 1020px) and (min-width: 940px) {
  .item {
    padding-top: 15px;
  }

  .imgHolder {
    img {
      max-width: 200px;
    }
  }

  .body {
    &:hover {
      img {
        max-width: 180px;
      }
    }
  }

  .imgHolder {
    margin-bottom: 10px;
  }

  .name {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .oldPrice {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .btns {
    column-gap: 10px;
  }
}

@media screen and (max-width: 780px) {
  .imgHolder {
    img {
      max-width: 200px;
    }
  }

  .item {
    padding-bottom: 16px;
  }

  .body {
    &:hover {
      img {
        max-width: 180px;
      }
    }
  }

  .imgHolder {
    margin-bottom: 10px;
  }

  .name {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .oldPrice {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .btns {
    column-gap: 10px;
  }

  .out {
    font-size: 20px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 705px) {
  .item {
    padding-top: 16px;
  }

  .imgHolder {
    height: 110px;
    img {
      max-width: 150px;
    }
  }

  .body {
    &:hover {
      img {
        max-width: 150px;
      }
    }
  }

  .name {
    font-size: 11px;
  }

  .btns {
    button {
      font-size: 11px;

      &:nth-child(1) {
        color: var(--primary);
        background: var(--yellow);
        padding: 8px 18px;
      }

      &:nth-child(2) {
        color: var(--yellow);
        background: transparent;
        padding: 8px 4px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .itemType {
    width: 45px;
    top: -10px;
    left: -13px;
  }

  .newCollectionImg {
    top: -4px;
    left: 10px;
  }

  .saleImg {
    top: 0;
    left: -3px;
  }
}

@media screen and (max-width: 610px) and (min-width: 540px) {
  .body {
    &:hover {
      img {
        max-width: 120px;
      }
    }
    img {
      max-width: 120px;
    }
  }
  .imgHolder {
    margin-bottom: 10px;
    height: auto;
  }

  .price {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .oldPrice {
    font-size: 11px;
    margin-bottom: 14px;
  }

  .top {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      margin-left: 14px;
    }

    padding: 0 13px;
  }

  .name {
    margin-bottom: 14px;
    font-size: 12px;
  }

  .btns {
    column-gap: 10px;
    button {
      font-size: 12px;

      &:nth-child(1) {
        color: var(--primary);
        background: var(--yellow);
        padding: 8px 20px;
      }

      &:nth-child(2) {
        color: var(--yellow);
        background: transparent;
        padding: 8px 6px;
      }
    }
  }

  .item {
    padding: 16px 0 12px;
  }

  .compare {
    font-size: 12px;
  }

  .out {
    font-size: 12px;
    margin-bottom: 40px;
  }

  .contactUs {
    padding: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .top {
    padding: 0 20px;
  }
}

@media screen and (max-width: 540px) and (min-width: 520px) {
  .price {
    font-size: 15px;
  }

  .oldPrice {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .body {
    &:hover {
      img {
        max-width: 130px;
      }
    }
    img {
      max-width: 130px;
    }
  }
  .imgHolder {
    margin-bottom: 10px;
    height: auto;
  }

  .price {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .oldPrice {
    font-size: 11px;
    margin-bottom: 14px;
  }

  .top {
    margin-bottom: 10px;
    span {
      font-size: 12px;
      margin-left: 14px;
    }

    padding: 0 13px;
  }

  .name {
    margin-bottom: 14px;
    font-size: 12px;
  }

  .btns {
    column-gap: 10px;
    button {
      font-size: 12px;

      &:nth-child(1) {
        color: var(--primary);
        background: var(--yellow);
        padding: 8px 20px;
      }

      &:nth-child(2) {
        color: var(--yellow);
        background: transparent;
        padding: 8px 6px;
      }
    }
  }

  .item {
    padding: 16px 0 12px;
  }

  .compare {
    font-size: 12px;
  }

  .out {
    font-size: 12px;
    margin-bottom: 40px;
  }

  .contactUs {
    padding: 5px;
    font-size: 12px;
  }

  .itemType {
    width: 45px;
    top: -10px;
    left: -13px;
  }

  .newCollectionImg {
    top: -4px;
    left: 10px;
  }

  .saleImg {
    top: 0;
    left: -3px;
  }
}

@media screen and (max-width: 380px) {
  .name {
    font-size: 11px;
  }

  .btns {
    button {
      font-size: 10px;

      &:nth-child(1) {
        color: var(--primary);
        background: var(--yellow);
        padding: 8px 18px;
      }

      &:nth-child(2) {
        color: var(--yellow);
        background: transparent;
        padding: 8px 4px;
      }
    }
  }
}

@media screen and (max-width: 1020px) and (min-width: 610px) {
  .top {
    padding: 0 15px;
  }
}
