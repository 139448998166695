.creditTerms {
  padding-bottom: 60px;
}

.terms {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  width: fit-content;
}

.title {
  color: white;
  margin-bottom: 40px;
}

.ul {
  list-style: initial;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-left: 20px;
  li {
    max-width: 700px;
    color: var(--lightGrey);
  }
}

.call {
  font-style: italic;
  color: white;
  font-size: 24px;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .call {
    font-size: 14px;
  }
}
